import React, { useEffect, useState } from 'react';
import { useWeb3Modal } from '@web3modal/react';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'

function ConnectButtonOverride() {

  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();

  const userAccount = useAccount();

    async function connectWallet(){
      open();
    }

    useEffect(() => {
      const connectButton1 = document.querySelector('.btn-top-primary.connect-button.w-button');
      const connectButton2 = document.querySelector('.btn-primary.center.w-inline-block');
      if(userAccount.address){
        connectButton1.textContent = `${userAccount.address.substring(0,4)}...${userAccount.address.slice(-4)}`;
        connectButton2.textContent = `${userAccount.address.substring(0,4)}...${userAccount.address.slice(-4)}`;
      }
      if (connectButton1) {
        connectButton1.addEventListener('click', connectWallet);
      }
      if (connectButton2) {
        connectButton2.addEventListener('click', connectWallet);
      }
    }, [userAccount]);
  
    return null;
  }
  
  export default ConnectButtonOverride;
  