import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import CONTRACT_INFO from './ContractInfo';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Button, Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';


function Mint() {
  const userAccount = useAccount();
  const userAddress = userAccount.address;

  const [x, setX] = useState(0);

  const [mintPrice, setMintPrice] = useState(null)
  const [publicMint, setPublicMint] = useState(false)

  const MAX_SUPPLY = 1500;

  async function getDetails(){
    const provider = new ethers.providers.JsonRpcProvider("https://eth-mainnet.g.alchemy.com/v2/Xdzr3kqzzXOQfJ2XkypVUIsVybGF387x");

    const dragonContract = new ethers.Contract(
      CONTRACT_INFO.contract,
      CONTRACT_INFO.abi,
      provider
    )
    const totalMinted = await dragonContract.totalSupply();

    const currentlyMinted = Number(totalMinted);

    const ratio = (currentlyMinted/MAX_SUPPLY)

    const percentageMinted = String(ratio*100) + '%';

    const bar1 = document.getElementById('mintPercentage1')
    const bar2 = document.getElementById('mintPercentage2')

    bar1.style.width = percentageMinted
    bar2.style.width = percentageMinted

    const mintText1 = document.getElementById('progressText1')
    const mintText2 = document.getElementById('progressText2')


    mintText1.textContent = `${totalMinted}/1500 MINTED`
    mintText2.textContent = `${totalMinted}/1500 MINTED`
  }


  async function updateStatus(){
    const provider = new ethers.providers.JsonRpcProvider("https://eth-mainnet.g.alchemy.com/v2/Xdzr3kqzzXOQfJ2XkypVUIsVybGF387x");

    const dragonContract = new ethers.Contract(
      CONTRACT_INFO.contract,
      CONTRACT_INFO.abi,
      provider
    )

    const weiAmount = await dragonContract.price();
    const etherAmount = ethers.utils.formatEther(weiAmount);

    setMintPrice(etherAmount)

    const isPublicMintOpen = await dragonContract.isPublicMinting();
    setPublicMint(isPublicMintOpen)
  }

  async function checkWhitelist() {
    console.log('check called')
    if (publicMint) {
      const hardcodedProof = ['0xd5b5f64d66cc31c622be4bdc9e83b48fafd599c8e2dcd4402032ab1c9f89dece'];
      return hardcodedProof;
    }
  
    // const backend = 'http://127.0.0.1:3000/wallet-check';
    const backend =  'https://moonrunners-dragons.herokuapp.com/wallet-check'
    
  
    try {
      const response = await fetch(backend, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: userAddress,
        }),
      });
      const data = await response.json();
      if (data._proof) {
        return data._proof;
      } else {
        console.log('Returning null (no proof)');
        return null;
      }
    } catch (error) {
      console.error('Error fetching proof:', error);
      return null;
    }
  }

  async function mintDragon() {

    console.log('mintDragon')
        
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = await provider.getSigner();

    console.log(signer)

    const result = await checkWhitelist();


    console.log(result)
    
    if (result !== null) {
      
      try {
        const dragonContract = new ethers.Contract(
          CONTRACT_INFO.contract,
          CONTRACT_INFO.abi,
          signer
          )

        const ethValue =( mintPrice * x )

        console.log(ethValue)

        const tx = await dragonContract.mint(result, { value: ethers.utils.parseEther((String(ethValue)))});
        console.log(tx)
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    const MAX_VALUE = 5;
  
    const handleIncreaseMint = () => {
      if (x < MAX_VALUE) {
        userMintAmount.value = x + 1;
        userMintAmount2.value = x + 1;
        setX(x + 1);
      }
    };

    const handleDecreaseMint = () => {
      if (x > 0) {
        userMintAmount.value = x - 1;
        userMintAmount2.value = x - 1;
        setX(x - 1);
      }
    };

    const mintButton = document.querySelector('.btn-cart.w-button.mint-button');
    const mintButton2 = document.querySelector('.btn-cart.w-button.mint-button2');
  
    const staticPrice = document.querySelector('.cart-form-text-middle');
    const staticPrice2 = document.querySelector('.cart-form-text-middle2');

    const userMintAmount = document.getElementById('numberOfMints')
    const userMintAmount2 = document.getElementById('numberOfMints2')

    const reduceMint1 = document.getElementById('reduceMint1')
    const reduceMint2 = document.getElementById('reduceMint2')
    const increaseMint1 = document.getElementById('increaseMint1')
    const increaseMint2 = document.getElementById('increaseMint2')

    userMintAmount.value = 0;
    userMintAmount2.value = 0;

    const handleMintClick = () => mintDragon();
    // const handleMintAmount = () => getUserMintAmount();
    // const handleDecreaseMint = () => decreaseMint();

    if (mintButton) {
      mintButton.addEventListener('click', handleMintClick);
    }
    if (mintButton2) {
      mintButton2.addEventListener('click', handleMintClick);
    }
    
  if (reduceMint1) {
    reduceMint1.addEventListener('click', handleDecreaseMint);
  }
  if (reduceMint2) {
    reduceMint2.addEventListener('click', handleDecreaseMint);
  }
  if (increaseMint1) {
    increaseMint1.addEventListener('click', handleIncreaseMint);
  }
  if (increaseMint2) {
    increaseMint2.addEventListener('click', handleIncreaseMint);
  }

  if(staticPrice){
    staticPrice.innerHTML = mintPrice;
  }
  if(staticPrice2){
    staticPrice2.innerHTML = mintPrice;
  }
  updateStatus();

  const updateStaticPrices = () => {
    if (staticPrice) {
      staticPrice.innerHTML = mintPrice * x;
    }
    if (staticPrice2) {
      staticPrice2.innerHTML = mintPrice * x;
    }
  };

  updateStaticPrices();
  getDetails();

  
    return () => {
      if (mintButton) {
        mintButton.removeEventListener('click', handleMintClick);
      }
      if (mintButton2) {
        mintButton2.removeEventListener('click', handleMintClick);
      }
      if (reduceMint1) {
        reduceMint1.removeEventListener('click', handleDecreaseMint);
      }
      if (reduceMint2) {
        reduceMint2.removeEventListener('click', handleDecreaseMint);
      }
      if (increaseMint1) {
        increaseMint1.removeEventListener('click', handleIncreaseMint);
      }
      if (increaseMint2) {
        increaseMint2.removeEventListener('click', handleIncreaseMint);
      }

    };

  
  }, [userAccount.address, mintPrice, publicMint, x]);
  

  return null;
}

export default Mint;
