import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Button, Web3Modal } from '@web3modal/react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';
import ConnectButtonOverride from './ConnectButtonOverride';
import Mint from './Mint';
// import { ethers } from 'ethers';

const chains = [arbitrum, mainnet, polygon];

const projectId = 'fjafmls';

const { provider } = configureChains(chains, [w3mProvider({ projectId: projectId, rpcUrl: `https://eth-mainnet.g.alchemy.com/v2/Xdzr3kqzzXOQfJ2XkypVUIsVybGF387x` })]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ version: 1, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

function App() {
  return (
    <>
      <WagmiConfig client={wagmiClient}>
      </WagmiConfig>
       <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      <ConnectButtonOverride /> 
      <Mint />
    </>
  );
}

export default App;
